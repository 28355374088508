const baseUrl = process.env.VUE_APP_API
const api = {
  state: {
    // 文件上传
    filesUploadApi: baseUrl + 'community/auth/file/upload',
    //富文本上传
    picturesUploadApi: baseUrl + 'community/crm/pictures',
  }
}

export default api
