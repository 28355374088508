import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '../views/layout/Layout'

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
  }
**/

export const constantRouterMap = [
  { path: '/login',
    meta: { title: '登录', noCache: true },
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401'),
    hidden: true
  },
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: 'dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: '首页',
        meta: { title: '首页', icon: 'index', noCache: true }
      }
    ]
  },
  {
    path: '/user',
    name:'user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'center',
        component: () => import('@/views/system/user/center'),
        name: '个人中心',
        meta: { title: '个人中心', icon: 'user' }
      }
    ]
  },
  {
    path: '/commAccdetail',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: '',
        component: () => import('@/views/community/customer/accmgr/accdetail'),
        name: 'commAccdetail',
        meta: { title: '账户详情', icon: 'index', noCache: true }
      }
    ]
  },
  {
    path: '/siteconfig',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: '',
        component: () => import('@/views/cms/site/commonConfig'),
        name: 'commonConfig',
        meta: { title: '站点配置', icon: 'index', noCache: true }
      }
    ]
  },
  // todo打包完就/apiWatchDetails就带个路径,bug
  {
    path: '/apiWatchDetails',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: '',
        name: 'apiWatchDetailsInner',
        component: () => import('@/views/monitor/apiWatch/detail/index'),
        meta: { title: 'client监控详情', icon: 'index', noCache: true }
      },
    ]
  },
  {
    path: '/resetPass',
    name: 'resetPass',
    component: () => import('@/views/login/resetPass')
  }
  // { path: '*', redirect: '/404', hidden: true }
]

export default new Router({
  // mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})
