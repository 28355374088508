import request from '@/utils/request'

export function initData(url, params) {
  return request({
    url: url,
    method: 'get',
    params
  })
}

//商户管理 接口开始
export function getSiteInfo(params) {
  return request({
    url: "/user/auth/siteInfo",
    method: 'get',
    params: params
  })
}

// login .ico 和siteName
export function getSiteInfoNew(params) {
  return request({
    url: "/cms/front/siteInfo",
    method: 'get',
    params: params
  })
}
