<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<script>
  import { getSiteInfoNew } from '@/api/data'
import { mapGetters } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters([
      'payTypeOptions'
    ])
  },
  data() {
    return {
      includePages:['2'],
      icoImg: 'https://oss-hk2069.oss-accelerate.aliyuncs.com/files/88b1011ce4234e8f9a4d91bfc2baae03.ico'
    }
  },
  created(){
    this.getInfo();
  },
  methods: {
    getInfo(){
      getSiteInfoNew({
        domainName:window.location.host,
      }).then((res) => {
        if(res.icon){
          // to
          this.SetTitleAndIcon(res.icon)
        }
      })
    },
    SetTitleAndIcon(icon){
      // document.title = res.siteName || 'ALITA社区';
      var linkIcon = document.createElement("link");
      // rel="shortcut icon"
      linkIcon.rel = "shortcut icon";
      linkIcon.href = icon;
      var ohead=document.getElementsByTagName("head")[0];
      ohead.appendChild(linkIcon);
    }
  }
}
</script>
