import axios from 'axios'
import router from '@/router'
import { Notification, MessageBox } from 'element-ui'
import store from '../store'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API, // api 的 base_url
  timeout: 900000 // 请求超时时间
})

// request拦截器
service.interceptors.request.use(
  config => {
    if (getToken()&&getToken()!='undefined') {
      config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    if (response.data.status !== 10000) {
        Notification.error({
          title: response.data.message
        })
        return Promise.reject('error')
    }
    if(response.data.data) {
      if(response.data.type==2){
        response.data = response.data.data.content
      }else if(response.data.type==3){
        response.data = response.data.data.content[0]
      }else {
        response.data= response.data.data
      }
    }
    return response.data;
    // const code = response.status
    // if (code < 200 || code > 300) {
    //   Notification.error({
    //     title: response.message
    //   })
    //   return Promise.reject('error')
    // } else {
    //   return response.data
    // }
  },
  error => {
    let code = 0
    try {
      code = error.response.data.status
    } catch (e) {
      if (error.toString().indexOf('timeout')) {
        Notification.error({
          title: '请求超时',
          duration: 2500
        })
        return Promise.reject(error)
      }
    }
    if (code === 401) {
      MessageBox.confirm(
        '登录状态已过期，您可以继续留在该页面，或者重新登录',
        '系统提示',
        {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        store.dispatch('LogOut').then(() => {
          location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      })
    } else if (code === 403) {
      router.push({ path: '/401' })
    } else {
      const errorMsg = error.response.data.message
      if (errorMsg !== undefined) {
        console.error(errorMsg)
      }

    }
    return Promise.reject(error)
  }
)
export default service
