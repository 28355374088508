import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
import '@/styles/index.less' // global css
import App from './App'
import router from './router'
import store from './store'
import '@/icons' // icon
import './permission' // permission control
import i18n from './lang' // Internationalization
import { parseTime } from './utils'
Vue.prototype.$parseTime = parseTime;
Vue.use(ElementUI, {
  locale:locale,
  i18n: (key, value) => i18n.t(key, value)
 })
Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
