import { render, staticRenderFns } from "./TagsView.vue?vue&type=template&id=37ed8bbb&scoped=true"
import script from "./TagsView.vue?vue&type=script&lang=js"
export * from "./TagsView.vue?vue&type=script&lang=js"
import style0 from "./TagsView.vue?vue&type=style&index=0&id=37ed8bbb&prod&rel=stylesheet%2Fless&lang=less&scoped=true"
import style1 from "./TagsView.vue?vue&type=style&index=1&id=37ed8bbb&prod&rel=stylesheet%2Fless&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37ed8bbb",
  null
  
)

export default component.exports