import router from './router'
import store from './store'
import { getToken } from '@/utils/auth' // getToken from cookie
import { getLoginPageSiteName } from '@/utils/index' // getToken from cookie
import { buildMenus } from '@/api/system/menu'
import { filterAsyncRouter } from './store/modules/permission'


const whiteList = ['/login','/resetPass']// no redirect whitelist

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    if(getLoginPageSiteName()){
      document.title = to.meta.title + ' -  ' + getLoginPageSiteName();
    }else {
      document.title = to.meta.title;
    }
  }
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      // next({ path: '/login' })
      next();
    } else {
      if (store.getters.roles.length === 0) { // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(res => { // 拉取user_info
          // 动态路由，拉取菜单
          loadMenus(next, to)
        }).catch((err) => {
          console.log(err)
          store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        })
      // 登录时未拉取 菜单，在此处拉取
      } else if (store.getters.loadMenus) {
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus').then(res => {})
        loadMenus(next, to)
      } else {
        next()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
      next()
    } else {
      if( Object.keys(to.query).length && to.query.siteId){
        next(`/login?redirect=${to.path}?siteId=${to.query.siteId}`)
      }else {
        next(`/login?redirect=${to.path}`) // 否则全部重定向到登录页
      }

    }
  }
})

export const loadMenus = (next, to) => {
  buildMenus().then(res => {
    const asyncRouter = filterAsyncRouter(res)
    asyncRouter.push({ path: '*', redirect: '/404', hidden: true })
    store.dispatch('GenerateRoutes', asyncRouter).then(() => { // 存储路由
      router.addRoutes(asyncRouter) // 动态添加可访问路由表
      next({ ...to, replace: true })// hack方法 确保addRoutes已完成
    })
  })
}

router.afterEach(() => {
})
