import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'cms/crm/srSite',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'cms/crm/srSite/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'cms/crm/srSite',
    method: 'put',
    data
  })
}

// 获取所有的站点
export function getSitesData(params) {
  return request({
    url: 'cms/crm/srSite',
    method: 'get',
    params: params
  })
}

// 通过name查询站点 params: siteName
export function querySiteThroughtName(params) {
  return request({
    url: 'community/crm/getSiteByName',
    method: 'get',
    params: params
  })
}

// 根据站点类型获取模板
export function getSiteModules(params) {
  return request({
    url: 'cms/crm/getSiteModules',
    method: 'get',
    params: params
  })
}

// 根据站点类型获取模板
export function copyPlatforminfo(data) {
  return request({
    url: '/community/crm/srPlatformInfo/copy',
    method: 'post',
    data
  })
}


